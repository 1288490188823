
import qs from 'qs'
import request from '@/utils/request'

// 岗位列表
export function jobPostList(params) {
    return request({
        url: '/blade-cms/job-post/open/list',
        method: 'get',
        params: params,
        // data: qs.stringify(params),
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        //     'Authorization':"Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
        //     'Tenant-Id':"000000",
        // }
    })
}


// 提交工作简历
export function jobResumeSave(params) {
    return request({
        url: '/blade-cms/job-resume/open/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

// 购物帮助列表
export function articleShoppingHelpList(params) {
    return request({
        url: '/blade-cms/article-shopping-help/open/list',
        method: 'get',
        params: params,
    })
}
// 购物帮助详情
export function articleShoppingHelpDetail(params) {
    return request({
        url: '/blade-cms/article-shopping-help/open/detail',
        method: 'get',
        params: params,
    })
}

// 售后服务列表
export function saleServiceList(params) {
    return request({
        url: '/blade-cms/article-after-sale-service/open/list',
        method: 'get',
        params: params,
    })
}
// 售后服务详情
export function saleServiceDetail(params) {
    return request({
        url: '/blade-cms/article-after-sale-service/open/detail',
        method: 'get',
        params: params,
    })
}

// 配送说明列表
export function articleDeliveryPage(params) {
    return request({
        url: '/blade-cms/article-delivery-instructions/open/page',
        method: 'get',
        params: params,
    })
}
// 配送说明详情
export function articleDeliveryDetail(params) {
    return request({
        url: '/blade-cms/article-delivery-instructions/open/detail',
        method: 'get',
        params: params,
    })
}


// 联系方式配置
export function websiteContactDetail(params) {
    return request({
        url: '/blade-cms/website-contact/open/detail',
        method: 'get',
        params: params,
    })
}





