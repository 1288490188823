<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
<!--            退款说明-->
			{{serviceInfo.title}}
        </div>
        <div v-if="serviceInfo">
            <div v-html="serviceInfo.contentText"></div>
        </div>


        <div v-if="false" class="vip-content-inner">
            <div class="rich-text">
               <p>1、首先，在我的淘宝界面中点击更多订单或者待收货。 2、然后，找到需要退款的产品，然后单击进入。 3、打开后，找到红色箭头指向的“退换”，然后单击打开。 4、接着，在弹出窗口中，选择仅显示退款或退货退款。以退货退款申请为例，单击进入退款界面。 5、接着，选择退货原因。填写退款金额和退换说明，可以看到此处退款说明为选填，根据实际填写即可，然后单击提交。</p>
            </div>
        </div>

    </div>
</template>

<script>
import Bus from "@/utils/bus";
import {articleDeliveryDetail} from "@/api/CMSmodule";

export default {
    name: "RefundInstructions",
	data(){
		return{
			serviceInfo:{}, // 帮助详情

		}
	},
	mounted(){
		// console.log(this.$route.query) // 获取传递参数
		Bus.$off('after-saleService1') // 注销
		Bus.$on("after-saleService1", (info) => { // 接收关键词
			let that=this
			console.log(info)
			this.getarticleDeliveryDetail(info)
		})
	},
	methods:{

		//  -------------------------接口请求
		getarticleDeliveryDetail(id){ // 获取配送说明详情
            articleDeliveryDetail({id:id}).then((res) => {
				if (res.data.code==200) {
					this.serviceInfo=res.data.data
					this.serviceInfo.contentText=this.$utils.showHtml(res.data.data.contentText)
				}else{

				}

			})
		},

	},
}
</script>

<style scoped>

</style>
